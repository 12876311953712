<template>
  <div>
    <page-title :heading="$t('accounting.lang_itemGroupMargin')" :icon="icon"
                :subheading="$t('accounting.lang_itemGroupMargin')"></page-title>
    <div class="app-main__inner">
      <WaregroupsMarginsComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle.vue";
import MarginsComponent from "@/components/analytics/warewanlytics/margins/ItemsMarginsComponent";
import WaregroupsMarginsComponent from "@/components/analytics/warewanlytics/margins/WaregroupsMarginsComponent";

export default {
  components: {
    WaregroupsMarginsComponent,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-graph1 icon-gradient bg-tempting-azure',
  })
}
</script>